import React from "react";

const Mastering = () => {
  return (
    <div id="mastering" className="serviceContainer reverse">
      <div className="description">
        <h2>Mastering</h2>
        <p>
          My mastering service provides the final touches to your music,
          bringing it to its full potential. I use state-of-the-art equipment
          and techniques to enhance the clarity, depth, and overall balance of
          your recordings, ensuring that they sound great across all platforms
          and devices. I carefully adjust levels, EQ, and dynamics to achieve a
          consistent sound and make your music stand out. Whether you need a
          master for streaming, CD, vinyl, or any other format, I will provide
          you with a high-quality product that will meet your needs. Contact me
          today to discuss your project and take the first step towards a
          polished, professional sound.
        </p>
        <div className="button-container">
          <a href="#contact">
            <button className="third-button">Get in Touch</button>
          </a>
        </div>
      </div>
      <img
        className="about-img"
        src="https://images.unsplash.com/photo-1565828751789-6de4e57775d1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwcm9maWxlLXBhZ2V8MzF8fHxlbnwwfHx8fHw%3D&auto=format&fit=crop&w=700&q=60"
        alt="Caezar mastering service"
      />
    </div>
  );
};

export default Mastering;
