import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import caezar1 from "../assets/photos/caezar1.jpg";
import caezar2 from "../assets/photos/caezar2.jpg";
import caezar3 from "../assets/photos/caezar3.jpg";
import caezar4 from "../assets/photos/caezar4.jpg";
import caezar5 from "../assets/photos/caezar5.jpg";
import caezar6 from "../assets/photos/caezar6.jpg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import SwiperCore, {
  Navigation,
  Thumbs,
  Pagination,
  Controller,
  Autoplay,
  A11y,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.min.css";
// import required modules
import { EffectCards } from "swiper";

SwiperCore.use([Navigation, Pagination, A11y, Controller, Thumbs, Autoplay]);

const About = () => {
  // ANIMATION
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".selector"),
      {
        opacity: 0,
        x: "100%",
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          markers: false,
          trigger: element.querySelector(".trigger"),
          start: "top+=20% 70%",
          end: "top+=500 60%",
          scrub: true,
          pinSpacing: true,
        },
      }
    );
  }, []);
  return (
    <div ref={ref}>
      <div className="trigger"></div>
      <h2 className="section-title selector">About</h2>
      <div className="aboutContainer">
        <Swiper
          effect={"cards"}
          grabCursor={true}
          modules={[EffectCards]}
          className="about-swiper"
        >
          <SwiperSlide>
            <img className="about-img" src={caezar1} alt="Caezar" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="about-img" src={caezar2} alt="Caezar" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="about-img" src={caezar3} alt="Caezar" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="about-img" src={caezar4} alt="Caezar" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="about-img" src={caezar5} alt="Caezar" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="about-img" src={caezar6} alt="Caezar" />
          </SwiperSlide>
        </Swiper>
        <div className="description">
          <h2>Something about the artist</h2>
          <p>
            Caezar is an exciting French and British artist currently based in
            London. His musical process reflects this diversity which is further
            broadened by his upbringing in Munich and is fully self-contained;
            from writing and performing, to producing, mixing, and mastering.
            Since his first single, ‘No One Else’ in June 2021, Caezar has
            evolved his musical style to incorporate electronic, vibrant
            features which complement an alternative pop sound. Now
            experimenting with advanced music production technology, including
            analogue synths, he looks to express themes surrounding his
            experience of early adulthood in a new city through a yet more
            intriguing lens.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
