import React from "react";

const Mixing = () => {
  return (
    <div id="mixing" className="serviceContainer">
      <img
        className="about-img"
        src="https://images.unsplash.com/photo-1509310202330-aec5af561c6b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bXVzaWMlMjBwcm9kdWN0aW9ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
        alt="Caezar mixing service"
      />
      <div className="description">
        <h2>Mixing</h2>
        <p>
          My mixing service offers a professional touch to enhance the quality
          of your music recordings. I will carefully blend and balance the
          individual tracks to produce a cohesive, polished sound that brings
          out the best in your music. Whether you need a mix for streaming,
          radio, or a live performance, I will provide you with a product that
          you can be proud of. Contact me today to discuss your project and take
          the first step towards a high-quality mix.
        </p>
        <div className="button-container">
          <a href="#contact">
            <button className="third-button">Get in Touch</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Mixing;
