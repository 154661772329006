import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import emailjs from "@emailjs/browser";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { HiMail } from "react-icons/hi";
import { RiMapPin2Fill } from "react-icons/ri";

const Contact = () => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
		emailjs
			.sendForm(
				"service_pz4lpli",
				"template_w7rrujp",
				form.current,
				"user_y1ftUXLPd92Uw3SY8iLtJ"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
		alert("Thank you for contacting me. I will be in touch soon! :)");
	};
	const [services, setServices] = React.useState("");

	const handleChange = (event) => {
		setServices(event.target.value);
	};

	// ANIMATION
	gsap.registerPlugin(ScrollTrigger);
	const ref = useRef(null);
	useEffect(() => {
		const element = ref.current;
		gsap.fromTo(
			element.querySelector(".selector"),
			{
				opacity: 0,
				x: "100vw",
			},
			{
				opacity: 1,
				x: 0,
				scrollTrigger: {
					markers: false,
					trigger: element.querySelector(".trigger"),
					start: "top+=20% 70%",
					end: "top+=500 60%",
					scrub: true,
					pinSpacing: true,
				},
			}
		);
	}, []);

	return (
		<div ref={ref}>
			<div className="trigger">
				<h2 className="section-title selector">CONTACT</h2>
				<div
					className="formContainer"
					id="contact">
					{/* <img src={contact} alt="Contact" className="about-img" /> */}
					<div className="contact-information">
						<div>
							<h2>Contact Information</h2>
							<p>
								Fill in the form and I will get back to you as soon as possible!
							</p>
						</div>
						<div className="contact-information-details">
							<div>
								<HiMail style={{ scale: "2", margin: "10px" }} />{" "}
								<span>
									<a href="mailto:ajcaesar.music@gmail.com">
										ajcaesar.music@gmail.com
									</a>
								</span>
							</div>
							<div>
								<RiMapPin2Fill style={{ scale: "2", margin: "10px" }} />{" "}
								<span>BERLIN / LONDON</span>
							</div>
						</div>
						{/* <div className="contact-information-social">
            <BsWhatsapp style={{ scale: "2" }} className="m-3" />
            <BsInstagram style={{ scale: "2" }} className="m-3" />
          </div> */}
					</div>
					<div className="description">
						<form
							onSubmit={sendEmail}
							ref={form}>
							<Form.Floating className="mb-3">
								<Form.Control
									id="floatingInputCustom"
									type="text"
									name="user_name"
									placeholder="John Doe"
								/>
								<label htmlFor="floatingInputCustom">Name</label>
							</Form.Floating>
							<Form.Floating className="mb-3">
								<Form.Control
									id="floatingInputCustom"
									type="email"
									name="user_email"
									placeholder="name@example.com"
									required
								/>
								<label htmlFor="floatingInputCustom">Email address</label>
							</Form.Floating>
							<FloatingLabel
								className="mb-3"
								controlId="floatingSelect"
								label="Service">
								<Form.Select
									aria-label="Select service"
									name="service"
									id="demo-simple-select-helper-label"
									value={services}
									onChange={handleChange}
									required>
									<option>Select service</option>
									<option value="Dj">Dj</option>
									<option value="Production">Production</option>
									<option value="Mixing">Mixing</option>
									<option value="Mastering">Mastering</option>
								</Form.Select>
							</FloatingLabel>
							<FloatingLabel
								className="mb-3"
								controlId="floatingTextarea2"
								label="Message">
								<Form.Control
									name="user_message"
									required
									as="textarea"
									placeholder="Leave a comment here"
									style={{ height: "100px" }}
								/>
							</FloatingLabel>
							<div className="button-container">
								<button
									type="submit"
									className="third-button">
									Send
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
