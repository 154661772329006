import React from "react";
import ableton from "../assets/logos/ableton.jpeg";
import logicPro from "../assets/logos/logic-pro.png";
import viola from "../assets/logos/viola.png";

const Production = () => {
  return (
    <div className="serviceContainer reverse">
      <div className="description">
        <h2>Production</h2>
        <p>
          I provide a music production service that offers professional-grade
          sound recordings and music compositions for personal or commercial
          projects. I work with clients to create custom background music for
          videos, audio tracks for podcasts, or full-length songs for albums.
          With my experience and expertise, I use state-of-the-art equipment and
          software to deliver high-quality recordings and compositions that are
          tailored to the client's specific needs and preferences. Let's work
          together to bring your musical vision to life. Contact me today to
          elevate your music to the next level.
        </p>
        <div className="production-tools">
          <img src={ableton} alt="Ableton" />
          <img src={logicPro} alt="Logic Pro" style={{ scale: "1.4" }} />
          <img src={viola} alt="Serum" style={{ scale: "1.3" }} />
        </div>
        <div className="button-container">
          <a href="#contact">
            <button className="third-button">Get in Touch</button>
          </a>
        </div>
      </div>
      <img
        className="about-img"
        src="https://images.unsplash.com/photo-1633933769681-dc8d28bdeb6d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
        alt="Caezar"
      />
    </div>
  );
};

export default Production;
