import React from "react";
import logo from "../assets/logos/logo.png";

const Logo = () => {
  return (
    <div className="LogoContainer noselect">
      <a href="#video-text">
        <img src={logo} alt="logo" className="noselect" />
      </a>
    </div>
  );
};

export default Logo;
