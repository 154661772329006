import React from "react";
import musicToBlame from "../assets/photos/music-to-blame.png";
const Reviews = () => {
  return (
    <div className="reviewsContenitor">
      <div className="review">
        <div className="review-text">
          The London based indie artist and song-writer Caezar has a distinctive
          voice that I can best draw comparisons to King Krule
        </div>
        <img
          src="https://findnoenemy.com/wp-content/uploads/2021/09/Black-on-Transparent-e1631638167376.png"
          alt="Find No Enemy, Kartik Sundar"
          className="review-img"
        />
        {/* <div className="review-writer">Find No Enemy, Kartik Sundar</div> */}
      </div>
      <div className="review">
        <div className="review-text">
          There are layers to Caezar’s vocals that subtly show off his range of
          abilities, for example, the descending harmonies and adlibs.
        </div>
        <img
          src={musicToBlame}
          alt="Music is to Blame,Rachel Feehan"
          className="review-img blame"
        />
        {/* <div className="review-writer">Music To Blame, Rachel Feehan</div> */}
      </div>
    </div>
  );
};

export default Reviews;
