import React, { useEffect, useRef, useState } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import { AiFillPlayCircle } from "react-icons/ai";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import SwiperCore, {
  Navigation,
  Thumbs,
  Pagination,
  Controller,
  Autoplay,
  A11y,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Pagination, A11y, Controller, Thumbs, Autoplay]);

const MusicVideo = () => {
  const [thumbsSwiper] = useState(null);
  // const [controlledSwiper] = useState(null);

  const [limelight] = useState(
    "https://open.spotify.com/embed/album/20ISR41F8fl3JDubEwMyUp?utm_source=generator&theme=0"
  );
  const [noOneElse] = useState("https://www.youtube.com/embed/kE1fSDiswhA");
  const [forgetAboutIt] = useState("https://www.youtube.com/embed/CXo8_vxIr7Y");
  const [quattroFormaggi] = useState(
    "https://open.spotify.com/embed/album/4dVIncZTzbkesX4Ts3trgk?utm_source=generator"
  );
  const [wrapperAroundYou] = useState(
    "https://open.spotify.com/embed/album/6ASfO4n69TeTRdRF9gTjxF?utm_source=generator"
  );
  const [strange] = useState(
    "https://open.spotify.com/embed/album/1eyPMA7L63X5CYc3blFVcn?utm_source=generator"
  );
  // const [company] = useState(
  //   "https://open.spotify.com/embed/album/3tsX5O9aA7hRycB4Hiac2g?utm_source=generator"
  // );
  const [atTwenty] = useState(
    "https://open.spotify.com/embed/album/3t3x7HRlKoShKMO7W3TNwi?utm_source=generator"
  );

  const [allAboutYou] = useState(
    "https://open.spotify.com/embed/album/3QDNhybtHRTiT8RIYe8phy?utm_source=generator"
  );
  const [seven30] = useState(
    "https://open.spotify.com/embed/album/0rScfQTNRqNK2BfYoW5woD?utm_source=generator"
  );
  const [fallingOnTheFloor] = useState(
    "https://open.spotify.com/embed/album/6du0p2fQz99vrruldRz8a1?utm_source=generator"
  );
  const [currentMusicVideo, setCurrentMusicVideo] = useState(
    "https://www.youtube.com/embed/kE1fSDiswhA"
  );

  const [songs] = useState([
    {
      name: "Limelight",
      produced: "Produced",
      mixed: "Mixed",
      date: "2023",
      author: "Caezar",
      authorLink: "https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM",
      img: "https://i.scdn.co/image/ab67616d00001e028ee2c32939939cfd46c8ac1b",
      onclick: limelight,
    },
    {
      name: "Quattro Formaggi",
      produced: "Produced",
      mixed: "Mixed",
      date: "2022",
      author: "Caezar",
      authorLink: "https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM",
      img: "https://i.scdn.co/image/ab67616d00001e02f8bc8d15c5ea88007de9c246",
      onclick: quattroFormaggi,
    },
    {
      name: "Forget About It",
      produced: "Produced",
      mixed: "Mixed",
      date: "2022",
      author: "Caezar",
      authorLink: "https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM",
      img: "https://i.scdn.co/image/ab67616d00001e02e524a421db6562d322777ed2",
      onclick: forgetAboutIt,
    },
    {
      name: "Wrapped Around You",
      produced: "Produced",
      mixed: "Mixed",
      date: "2022",
      author: "Caezar",
      authorLink: "https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM",
      img: "https://i.scdn.co/image/ab67616d00001e02672bf0611fa7cf68e1974245",
      onclick: wrapperAroundYou,
    },
    {
      name: "No One Else",
      produced: "Produced",
      mixed: "Mixed",
      date: "2022",
      author: "Caezar",
      authorLink: "https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM",
      img: "https://i.scdn.co/image/ab67616d00001e0282b4aa5faa6d5f9062454fba",
      onclick: noOneElse,
    },
    {
      name: "Strange",
      produced: "Produced",
      mixed: "Mixed",
      author: "Caezar",
      authorLink: "https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM",
      featuring: "Zach Harrison",
      featuringLink: "https://open.spotify.com/artist/1pfiGq8VWToLjKIKdq3oZn",
      date: "2021",
      img: "https://i.scdn.co/image/ab67616d00001e02ac41c939ded1c2605463d7ee",
      onclick: strange,
    },
    {
      name: "At Twenty",
      produced: "Produced",
      mixed: "Mixed",
      author: "Holda Sek",
      authorLink: "https://open.spotify.com/artist/2K290ORYajsijWi3Y875fP",
      date: "2022",
      img: "https://i.scdn.co/image/ab67616d00001e02b416628a88042262bf786b54",
      onclick: atTwenty,
    },
    {
      name: "Falling On The Floor",
      produced: "Produced",
      mixed: "Mixed",
      author: "Zach Harrison",
      authorLink: "https://open.spotify.com/artist/1pfiGq8VWToLjKIKdq3oZn",
      date: "2022",
      img: "https://i.scdn.co/image/ab67616d00001e027ab82259a63c543ee7a00f0d",
      onclick: fallingOnTheFloor,
    },
    {
      name: "All About You",
      produced: "Produced",
      mixed: "Mixed",
      author: "Milo Samuels",
      authorLink:
        "https://open.spotify.com/artist/4Tzrmn7ZWJ3ojNTp53gJLP?si=964d77bbd7e843da",
      date: "2022",
      img: "https://i.scdn.co/image/ab67616d00001e022453cc6cb74008f5a63d17e5",
      onclick: allAboutYou,
    },
    {
      name: "730",
      mixed: "Mixed",
      author: "Giia",
      authorLink:
        "https://open.spotify.com/artist/04GFyI0Np7Zo5oJrLvQ0ik?si=TzfFkImjREeC1G6FFDtRNA",
      date: "2023",
      img: "https://i.scdn.co/image/ab67616d0000b2736011f021260755684bace8a5",
      onclick: seven30,
    },
  ]);

  // ANIMATION
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".selector"),
      {
        opacity: 0,
        x: "100vw",
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          markers: false,
          trigger: element.querySelector(".trigger"),
          start: "top+=20% 70%",
          end: "top+=500 60%",
          scrub: true,
          pinSpacing: true,
        },
      }
    );
  }, []);
  return (
    <div ref={ref}>
      <div className="trigger"></div>
      <h2 className="section-title selector">Music</h2>
      <div className="musicVideo">
        <div className="frame-container">
          <iframe
            title="Music Video"
            className="noselect"
            src={currentMusicVideo}
            width="100%"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
        <Swiper
          spaceBetween={10}
          pagination={{ clickable: true }}
          slidesPerView={1}
          a11y={false}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          className="musicVideoSwiper noselect"
        >
          {songs.map((song) => (
            <SwiperSlide
              key={song.name}
              onClick={() => setCurrentMusicVideo(song.onclick)}
            >
              <div
                className="slide-text thumnailImg"
                style={{ background: `url("${song.img}")` }}
              >
                <div className="services-provided">
                  {song.produced && (
                    <span className="producing-icon">{song.produced}</span>
                  )}
                  {song.mixed && (
                    <span className="mixing-icon">{song.mixed}</span>
                  )}
                  {song.mastered && (
                    <span className="mastering-icon">{song.mastered}</span>
                  )}
                </div>
                <div className="opac-background">
                  <div className="slide-description">
                    <h3>{song.name}</h3>
                    <div className="author-name-contenitor">
                      <span>
                        <a
                          href={song.authorLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {song.author}
                        </a>
                      </span>
                      {song.featuring && (
                        <span>
                          <a
                            href={song.featuringLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            , {song.featuring}
                          </a>
                        </span>
                      )}
                    </div>
                  </div>
                  <AiFillPlayCircle
                    style={{ fontSize: "50px", color: "var(--primary-color)" }}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MusicVideo;
