import React from "react";
import background from "../assets/photos/no-one-else.mp4";
import { BsSpotify } from "react-icons/bs";
import { FaDeezer } from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import { GrSoundcloud } from "react-icons/gr";
import Poster from "../assets/photos/poster.png";

const TextVideoBackground = () => {
  return (
    <div id="video-text" style={{ width: "100vw" }}>
      <div className="video-container">
        <video
          preload="true"
          autoPlay={true}
          loop={true}
          controls={false}
          playsInline
          muted
          poster={Poster}
        >
          {" "}
          <source src={background} type="video/mp4" />
        </video>
        <div className="text-box">
          <div className="opac-background-hero">
            <img
              src="https://i.scdn.co/image/ab67616d00001e028ee2c32939939cfd46c8ac1b"
              alt="Limelight"
            />
            <span className="noselect carmine"></span>
            <span className="noselect brooklyn">LIMELIGHT</span>
            <span className="noselect sembra">Caezar</span>

            <div className="social">
              <a
                href="https://open.spotify.com/track/6ce25r7yR5mPP1j6uifhsy?si=72bf3648d318436a"
                className="social-icon"
                target="_blank"
                rel="noreferrer"
              >
                <BsSpotify />
              </a>
              <a
                href="https://soundcloud.com/aj_caesar/limelight"
                className="social-icon"
                target="_blank"
                rel="noreferrer"
              >
                <GrSoundcloud />
              </a>
              <a
                href="https://music.apple.com/us/album/limelight-single/1693396198"
                className="social-icon"
                target="_blank"
                rel="noreferrer"
              >
                <SiApplemusic />
              </a>
              <a
                href="https://www.deezer.com/en/album/455432085"
                className="social-icon"
                target="_blank"
                rel="noreferrer"
              >
                <FaDeezer />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextVideoBackground;
