import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";

const NewDj = () => {
  // ANIMATION
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".selector"),
      {
        opacity: 0,
        x: "100vw",
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          markers: false,
          trigger: element.querySelector(".trigger"),
          start: "top+=20% 70%",
          end: "top+=500 60%",
          scrub: true,
          pinSpacing: true,
        },
      }
    );
  }, []);
  return (
    <div ref={ref}>
      <div className="trigger"></div>
      <h2 className="section-title selector">Dj</h2>
      <div className="serviceContainer reverse">
        <div className="description">
          <h2>DJ Services</h2>
          <p>
            With years of experience and a passion for music, I provide a
            professional and unforgettable experience for you and your guests. I
            have an extensive library of music, including the latest hits and
            classic favorites. I am happy to take requests and work with you to
            create a playlist that matches your taste and the atmosphere of your
            event. Contact me today to discuss :)
          </p>
          <div className="button-container">
            <a href="#contact">
              <button className="third-button">Get in Touch</button>
            </a>
          </div>
        </div>
        <div className="about-img">
          <iframe
            className="dj-iframe"
            title="DJ Mix"
            width="100%"
            height="300"
            allow="autoplay"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1472029309&color=%23fc3c9c&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default NewDj;
