import React, { useEffect, useRef } from "react";
import { gsap, ScrollTrigger } from "gsap/all";
import Production from "../components/Production";
import Mastering from "../components/Mastering";
import Mixing from "../components/Mixing";

const Services = () => {
  // ANIMATION
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector(".selector"),
      {
        opacity: 0,
        x: "100vw",
      },
      {
        opacity: 1,
        x: 0,
        scrollTrigger: {
          markers: false,
          trigger: element.querySelector(".trigger"),
          start: "top+=20% 70%",
          end: "top+=500 60%",
          scrub: true,
          pinSpacing: true,
        },
      }
    );
  }, []);
  return (
    <div ref={ref}>
      <div className="trigger"></div>
      <h2 className="section-title selector">Services</h2>
      <div>
        <Production />
        <Mixing />
        <Mastering />
      </div>
    </div>
  );
};

export default Services;
