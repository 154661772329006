import React from "react";
import Subscribe from "./Subscribe";
import { BsInstagram, BsYoutube, BsSpotify } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { SiApplemusic } from "react-icons/si";
import { GrSoundcloud } from "react-icons/gr";

const Footer = () => {
  return (
    <>
      <Subscribe />
      <footer>
        <div className="logo">CAEZAR MUSIC</div>
        <div className="social">
          <a
            href="https://www.instagram.com/austincaezar/?hl=en-gb"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram />
          </a>
          <a
            href="https://www.tiktok.com/@austincaezar?_t=8auqbXE3nUL&_r=1"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <FaTiktok />
          </a>
          <a
            href="https://open.spotify.com/artist/1a3aItt1Y7XYfYMHjV17JM"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <BsSpotify />
          </a>
          <a
            href="https://soundcloud.com/aj_caesar/"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <GrSoundcloud />
          </a>
          <a
            href="https://music.apple.com/us/artist/caezar/1568082795"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <SiApplemusic />
          </a>
          <a
            href="https://www.youtube.com/@caezarmusic"
            className="social-icon"
            target="_blank"
            rel="noreferrer"
          >
            <BsYoutube />
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
