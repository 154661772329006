import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
const Subscribe = () => {
  // const notification = useNotification();
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_pz4lpli",
        "template_w02l40u",
        form.current,
        "user_y1ftUXLPd92Uw3SY8iLtJ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    alert("Email Send");
  };
  return (
    <div className="footer">
      <div className="email">
        <h3>Be the first to know about upcoming gigs</h3>
        <form onSubmit={sendEmail} ref={form}>
          <p>Stay up to date with new music releases, shows and merch!</p>
          <div className="form-container">
            <input
              type="email"
              id="email"
              placeholder="Enter your email"
              label="Email"
              name="user_email"
              variant="outlined"
              required
            />
            <button type="submit" className="primary-button">
              SUBSCRIBE
            </button>
          </div>
          <span>
            By clicking Subscribe Now you're confirming that you agree with our{" "}
            <u>
              <a
                href="https://www.termsfeed.com/live/436fe82f-b6cb-4ce9-86f8-74a788833efc"
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>
            </u>
            .
          </span>
        </form>
      </div>
    </div>
  );
};

export default Subscribe;
