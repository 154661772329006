import React from "react";
import "./App.css";
import About from "./components/About";
import Reviews from "./components/Reviews";
import MusicVideo from "./components/MusicVideo";
import Footer from "./components/Footer";
import Services from "./views/Services";
import Contact from "./components/Contact";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Dj from "./components/Dj";
import TextVideoBackground from "./components/TextVideoBackground";
import Logo from "./components/Logo";
// SECTIONS SCROLL FOCUS
import { useLayoutEffect, useState } from "react";
import clsx from "clsx";
import "./index.css";
import { BsMusicNoteBeamed, BsHeadphones } from "react-icons/bs";
import { VscAccount } from "react-icons/vsc";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import "react-notifications/lib/notifications.css";
import "bootstrap/dist/css/bootstrap.min.css";

// helpers
const clamp = (value) => Math.max(0, value);

const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

// hooks
const useScrollspy = (ids, offset = 0) => {
  const [activeId, setActiveId] = useState("");

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.pageYOffset;

      const position = ids
        .map((id) => {
          const element = document.getElementById(id);

          if (!element) return { id, top: -1, bottom: -1 };

          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { id, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveId(position?.id || "");
    };

    listener();

    window.addEventListener("resize", listener);
    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("resize", listener);
      window.removeEventListener("scroll", listener);
    };
  }, [ids, offset]);

  return activeId;
};

function App() {
  const ids = ["about", "music", "dj", "services", "contact"];
  const activeId = useScrollspy(
    ["about", "music", "dj", "services", "contact"],
    500
  ); // 54 is navigation height
  return (
    <div className="App">
      <>
        <div className="NavigationBarContainer">
          <div className="navigation-bar">
            <a
              href={`#music`}
              key={`menu-item-music`}
              className={clsx(
                "menu-item",
                ids[1] === activeId && "menu-item-active"
              )}
            >
              <div className="navigation-bar-item">
                <BsMusicNoteBeamed style={{ scale: "1.2" }} />
              </div>
            </a>
            <a
              href={`#about`}
              key={`menu-item-about`}
              className={clsx(
                "menu-item",
                ids[0] === activeId && "menu-item-active"
              )}
            >
              <div className="navigation-bar-item">
                <VscAccount style={{ scale: "1.2" }} />
              </div>
            </a>
            <a
              href={`#dj`}
              key={`menu-item-dj`}
              className={clsx(
                "menu-item",
                ids[2] === activeId && "menu-item-active"
              )}
            >
              <div className="navigation-bar-item">
                <BsHeadphones style={{ scale: "1.2" }} />
              </div>
            </a>
            <a
              href={`#services`}
              key={`menu-item-services`}
              className={clsx(
                "menu-item",
                ids[3] === activeId && "menu-item-active"
              )}
            >
              <div className="navigation-bar-item">
                <MdOutlineMiscellaneousServices style={{ scale: "1.2" }} />
              </div>
            </a>
            <a
              href={`#contact`}
              key={`menu-item-contact`}
              className={clsx(
                "menu-item",
                ids[4] === activeId && "menu-item-active"
              )}
            >
              <div className="navigation-bar-item">
                <HiOutlineMail style={{ scale: "1.2" }} />
              </div>
            </a>
          </div>
        </div>
      </>
      <Logo />
      <TextVideoBackground />
      <div className="main-contenitor">
        {/* <AnimationTest /> */}
        <Reviews />
        <section key={`section-text-music`} id="music">
          <MusicVideo />
        </section>
        <section key={`section-text-about`} id="about">
          <About />
        </section>
        <section key={`section-text-dj`} id="dj">
          <Dj />
        </section>
        <section key={`section-text-services`} id="services">
          <Services />
        </section>
        <section key={`section-text-contact`} id="contact">
          <Contact />
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
